.card {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.headingDiv {
    border-bottom: 1px dashed #D3D8DA;
}

.headingInvite{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.bottonDiv {
    float: right;
    margin-top: 65px;
}

.field_box .react-tel-input .form-control {
    width: 100% !important;
  }


  .react-tel-input .country-list .flag {
    position: unset !important;
  }

  .react-tel-input .form-control:focus {
    /* border-color: transparent !important; */
    box-shadow: none !important;
  }

  .react-tel-input .country-list {
      width: 275px !important;
  }

  .orgDetailsContainer .second-row{
    margin-top: 0 !important;
  }