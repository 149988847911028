
.MuiPaper-elevation0 section {
    width: 100% !important;
}

.routes  header {
    padding: 16px 0 !important;
}


/* button.MuiTab-root.MuiTab-textColorInherit {
    width: 50% !important;
}

.MuiTabs-indicator {
    width: 50% !important;
} */