.signInClass {
    width: 100% !important;
    margin: auto;
    border: unset;
    background-image: url('../../assets//images/khushbo_background.png');

}

.signInClass div div div{
    border:none !important;
    background-repeat: no-repeat;
    /* background: none !important; */
    /* background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FFFFFFDA 63%,
     #FFFFFF00 100%) 0% 0% no-repeat padding-box !important; */
     
    
}
 
.signInClass div div div svg{
    top: 0;
}

.signInClass form div {
    background: none !important;
    background: #fff;
}

.signInClass section h1 {
    font-family: Roboto !important;
    color:#5A5A5A !important;
    font-weight: 400;
    font-size: 30px !important;
}

main section form section div:nth-child(2) label span:nth-child(2) p {
    font-family: Roboto !important;
    color:#D3D8DA !important;
    font-size: 14px !important;
}
/* .signInClass section p {
    font-family: Roboto !important;
    color:#D3D8DA !important;
    font-size: 14px !important;
} */



/* .signInClass div > main{
    width: auto !important;

} */
