
.MuiPaper-elevation0 section {
    width: 100% !important;
}

.addOrganizationTabs .MuiPaper-elevation0{
    padding: 16px 0px !important;
    
}

.filter button span{
    text-transform: uppercase;
    font-weight: 500;
}

.filter button:hover{
    background: none;
}

.button_text{
    font-size: 14px !important;
    font-weight: 500;
}

.MuiButton-startIcon {
    display: inherit !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.table .status {
    text-transform: capitalize;
}

/* button.MuiTab-root.MuiTab-textColorInherit {
    width: 50% !important;
}

.MuiTabs-indicator {
    width: 50% !important;
} */

.sticky-proceed-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: #E4E8EC 1px solid;
    left: 0;
    padding: 15px 40px;
}

.noBorder {
    border: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.formContainerHikmah .noBorder {
    border: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

@media (max-width:1480px) {
    .sticky-proceed-footer {
        width:82%;
    }
}

.proceed-btn{
	height: 35px;
	width: auto;
    background-color: #2490F9 !important;
    border-radius: 25px !important;
    margin-left: 16px !important;
    margin-right: 100px !important;
}

.cancel-next-btn {
	display: flex;
	justify-content: flex-end;
}



.title-top-section {
    text-align: left !important;
    font-family: "Roboto";
    font-size: 28px;
    color: #4d4d4d;
    text-transform: capitalize;
    margin: 0 !important;
    border-bottom: 0 !important;
    font-weight: 300;
    padding-bottom: 0px !important;
}

.field_box.feature-table .tableBody .tableScroll {
    width: 100% !important;
}

.short-description {
    margin-bottom: 20px !important;
}
.short-description .MuiOutlinedInput-root{
    border-radius: 0 !important;
}

.short-description .description-label {
    color: #818E94;
    font-size: 12px;
    margin-bottom: 2px;
}

.description-count {
    color: #B6B9B9;
    font-size: 12px;
    position: relative;
    top: 7px;
    display: flex;
    justify-content: flex-end;
}

.short-description .MuiOutlinedInput-root.MuiInputBase-formControl {
    width: 100%;
}

.hardware-switch {
    box-shadow: 0 0 4px lightgrey;
    margin: 10px 0;
    padding: 10px;
}


.hardware-switch .FormSwitch h6 {
    margin-top: 5px;
}

.hardware-switch .drawer_list_container.card.FormSwitch div {
    margin-bottom: 0 !important;
}

.input-fields-bottom-line {
    border-bottom: #D3D8DA dashed 1.5px;
}

.disabledStepper {
    background-color: lightgray !important;
}

.side-drawer__content .MuiPaper-outlined {
    border: 0 !important;
    padding: 0 7px;
}

.field_box .options::before {
    border-bottom: 1px solid #D3D8DA !important;
}

.formContainerHikmah{
    width: 570px;
    margin: 0 auto;
}

.bottomSpacing{
    margin-bottom: 150px;
}

.feature-table .MuiTableCell-root.MuiTableCell-body div > img:nth-child(2) {
    width: 0px !important;
}

.plan-listing-table div > img:nth-child(2) {
    width: 0px !important;
}

/* #root > section > section > section > section > section > div > div > div:nth-child(2) > div > p > div > div > table > div > div > tbody  > th:nth-child(6) > div > img:nth-child(2) {
    width: 0px !important;

} */