.otpContainer {
    background-image: url('../../assets/images/khushbo_background.png');
}

.otpContainer div{
    
    

}

.otpContainer > div > div > div{
    border: none;
}

.otpContainer article {
    font-family: Roboto;
    font-size: 30px;
    color: #5A5A5A;
    font-weight: 300;
}

input:focus {
    outline: none;
}

.header_inner_alert{display: none;}

