.card {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.headingDiv {
    border-bottom: 1px dashed #D3D8DA;
}

.headingInvite{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.bottonDiv {
    float: right;
    margin-top: 65px;
}

