.card {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.headingDiv {
    border-bottom: 1px dashed #D3D8DA;
}

.headingInvite{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.headingLeftSidebar
{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 22px;
    letter-spacing: 0px;
    opacity: 1;
}

.bottonDiv {
    float: right;
    margin-top: 65px;
    height: auto;
    display: block;
}

.bottonDiv2{
    margin-top: 620px;
    padding-top: 40px;
    border-top: 1px dashed #D3D8DA;
    height: auto;
    display: block;
    position: relative;
}


.roletable > div > div{
    display: none !important;
}

.roletable > div{
    background: transparent;
}

.columnsClasses {
    padding: 16px !important;
}


.addtionText {
    color: #2490F9;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.memberConsole {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 40px;
}


.allowMemberText {
    margin: 0px;
    color: #818E94;
    letter-spacing: 0px;
}

.addAdditionalRole{
    display: inline-block;
    width: 400px;
    float: right;
}

.routes {
    padding-top: 30px;
    padding-left: 70px;
    height: 965px;
    width: 100%;
    display: flex;
  }

