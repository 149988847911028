
.MuiPaper-elevation0 section {
    width: 100% !important;
}

.addOrganizationTabs .MuiPaper-elevation0{
    padding: 16px 0px !important;
    
}

.filter button span{
    text-transform: uppercase;
    font-weight: 500;
}

.filter button:hover{
    background: none;
}

.button_text{
    font-size: 14px !important;
    font-weight: 500;
}

.MuiButton-startIcon {
    display: inherit !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.table .status {
    text-transform: capitalize;
}

/* button.MuiTab-root.MuiTab-textColorInherit {
    width: 50% !important;
}

.MuiTabs-indicator {
    width: 50% !important;
} */