.FormButtonContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.FormButtonContainer .downloadBtn {
    border-radius: 100px;
    font-size: 14px;
    color: #4d4d4d;
    text-transform: uppercase;
    letter-spacing: 1.26px;
    font-weight: 600;
    border: 1px solid #e8e9e9;
}

.ReceiptsContainer{
    max-width: 970px;
    margin: 0 auto;
    position: relative;
  }
  
  .ReceiptsForm{
    min-height: 400px;
    background: #fff;
    border:#E8E9E9 1px solid;
    margin-top: 15px;
    padding: 50px 20px;
  }
  
  .ReceiptsContainer .backBtn{
    margin-top: 0 !important;
    left: 0 !important;
    position: relative !important;
  }
  
  .ReceiptsForm .RcsptHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0px 64px 0 31px;
  }
  
  .ReceiptsForm .DcLogoRcspt {
    width: 190px;
    object-fit: contain;
    position: relative;
    top: 15px;
}
  .ReceiptsForm .RcsptHeaderInfo h2{
    font-size: 20px;
    color: #4D4D4D;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 6px;
  }
  
  .ReceiptsForm .RcsptHeaderInfo p{
    font-size: 16px;
    color: #818E94;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
  }
  
  .ReceiptsForm .RcsptHeaderInfo p span{
    color: #4D4D4D;
    font-weight: 500;
  
  }
  
  .ReceiptsForm .RcsptHeaderInfo p label{
    color: #3CC480;
    font-weight: 600 !important;
  
  }
  
  .BillInfromation{
    background: #F7F8FA;
    padding: 30px;
    margin-top:30px;
  }
  
  .BillInfromation{}
  
  .BillInfromation .RcsptHeaderInfoDetails h2{
    font-size: 14px;
    color: #818E94;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    margin-bottom: 6px;
    text-transform: uppercase;
  }
  
  .BillInfromation .RcsptHeaderInfoDetails h3{
    font-size: 20px;
    color: #4D4D4D;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    margin-bottom: 6px;
    letter-spacing: 0;
  }
  
  .BillInfromation .RcsptHeaderInfoDetails p{
    font-size: 16px;
    color: #4D4D4D;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    letter-spacing: 0;
  }
  
  .RcptBiilingTable{
    padding: 30px;
  } 
  .RcptBiilingTable .MuiPaper-elevation1{
    box-shadow: none !important;
  }
  
  .RcptBiilingTable .MuiTableRow-head .MuiTableCell-root{
    font-size:14px;
    font-weight: 500;
    color: #818E94;
    padding: 0px 0 !important;
    text-transform: uppercase;
  }
  
  .RcptBiilingTable .MuiTableBody-root .MuiTableCell-root {
    font-size: 14px;
    font-weight: 500;
    color: #4D4D4D;
    padding: 16px 0 !important;
  }
  
  .TotalDetailsContainer{
    padding: 0 30px;
  }
  
  .TotalDetails {
    text-align: right;
    width: 292px;
    float: right;
}
  
  .TotalDetails p{
    font-size: 14px;
    color: #818E94;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    letter-spacing: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  
  .TotalDetails .boldText{
    font-weight: 600;
    color:#4D4D4D;
  }
  
  .RscptFormFooter{
    border-top:#E8E9E9 1px solid;
    margin-top: 338px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
  }
  
  .RscptFormFooter p{
    font-size: 16px;
    color: #818E94;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    letter-spacing: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 2.24px;
  }

  .invoiceAmountText{
    text-align: left;
  }