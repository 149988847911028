.OrgDetailsContainer{
    margin-top: 70px;
}
.OrgLogoHeaderContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DetailsTitleContainer{
    display: flex;
}

.OrgLogocol{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #00000014;
    border-radius: 100px;
    opacity: 1;
    width: 50px;
    height: 50px;
    padding: 10px;

}

.OrgLogocol img{
    width: 100%;
}

.OrgDetailsTitle {
    margin-left: 10px;
    display: flex;
    align-items: center;
}
.OrgDetailsTitle h3{
    color: #4D4D4D;
    font-size: 20px;
    margin: 0;
}

.OrgDetailsTitle h3 p{
    color: #818E94;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
}

