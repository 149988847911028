
.MuiExpansionPanel-root.Mui-expanded {
    margin: 0 !important;
    border-top: 1px solid #E8E9E9;
    border-bottom: 1px solid #E8E9E9;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.react-tel-input .country-list .flag {
    position: unset !important;
  }

  .react-tel-input .form-control:focus {
    /* border-color: transparent !important; */
    box-shadow: none !important;
  }

  .react-tel-input .country-list {
      width: 275px !important;
  }