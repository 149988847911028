body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.detailsContainer {
	border: 1px solid #E8E9E9;
	box-shadow: 0px 2px 4px #0000001A;
	font-family: 'Roboto', sans-serif;
}

.detailsHeader {
    padding: 0px 30px;
    border-bottom: 1px solid #E8E9E9;
    height: 60px;
    display: flex;
    align-items: center;
}

.detailsHeaderTitle {
	color: #5A5A5A;
	display: inline-block;
    font-weight: 300;
	margin: 0;
	font-size: 24px;

}

.labelsContent {
	display: inline-block;
	vertical-align: super;
	margin-left: 10px;
}

.detailsLabel {
	text-transform: uppercase;
	border-radius: 20px;
	padding: 8px 15px;
	font-size: 14px;
	font-weight: 500;
}

.detailsLabel.approved {
	color: #3CC480;
	background: #EFFBF5;
	margin-left: 5px;
}

.detailsLabel.onboarding {
	color: #EEB000;
	background: #FEF9EB;
	margin-left: 5px;

}

.detailsLabel.blocked {
	color: #C44058;
	background: #F9EBED;
	margin-left: 5px;

}

.detailsListingContainer {
	padding: 20px 30px;
	display: flex;
    justify-content: space-between;
}

.detailsMainContent {
	display: inline-block;
	width: 330px;
}

.detailsListings, .listingTitle, .listingPlainText {
	display: inline-block;
}

.detailsListings {
	width: 330px;
	margin-bottom: 15px;
	display: flex;
}

.detailsListings .listingTitle {
	margin: 0;
	float: left;
	color: #818E94;
	font-size: 14px;
	font-family: Open Sans;
	font-weight: 400;
	width: 120px;
} 

.detailsListings .listingPlainText {
	color: #4D4D4D;
	font-size: 14px;
	font-family: Open Sans;
	font-weight: 600;
	margin: 0;
}
.detailsListings .listingPlainText br{
	display: none;
}

.listCheckImg {
	width: 14px;
}

.detailsListingFooter {
	border-top: 1px solid #E8E9E9;
	padding: 20px 30px;
    text-align: right;
}

.detailsListingFooter a {
	color: #2490F9;
	font-family: Roboto;
	font-weight: 500;
	font-size: 14px;
	text-decoration: none;
}


.makeStyles-class15-342 {
	padding: 16px !important;
}



/* Organization Detial page Tab 4 of Logs Css start */
.agreements-container {
	border: 1px solid #E8E9E9;
	box-shadow: 0px 2px 4px #0000001A;
	font-family: 'Roboto', sans-serif;
}

.agreement-title {
	padding: 15px 30px;
	border-bottom: 1px solid #E8E9E9;
}

.agreement-title h1 {
	color: #5A5A5A;
	font-family: Roboto;
	font-size: 24px;
	margin: 0;
	font-weight: 400;
}

.agreement-table {
	width: 100%;
	background: #F7F8FA;
	overflow-x: auto;
}

.agreement-table th {
	text-align: left;
	color: #818E94;
	font-family: Roboto;
	font-size: 14px;
	padding: 20px 0px 20px 30px;
	font-weight: 400;
	border-bottom: 1px solid #E8E9E9;
}

.agreement-table-head {
	border: 1px solid #D3D8DA;
}

.agreement-table-rows {
	background: #fff;
}

.agreement-table-rows td {
	padding: 20px 30px;
	border-bottom: 1px solid #E8E9E9;

}

.agreement-footer td {
	padding: 20px 30px;
	text-align: right;
}

.arrow-down {
	vertical-align: inherit;
	margin-left: -15px;

}

.MuiSvgIcon-root {
	width: 24px;
	height: 24px;
	vertical-align: middle;
}

#left-arrow {
	fill:#d3d8da;
}

#right-arrow {
	fill:#bbbbbb;
}

.pagination-img {
	vertical-align: middle;
	margin: 0 10px;
}

.no-of-rows {
	color: #818E94;
	font-size: 14px;
}

.pagination {
	color: #818E94;
	font-size: 14px;
}

.left-right-icon {
	margin: 0 10px;
}

.agreement-footer {
	background: #fff;
}

.paginationNo {
	border: none;
	background: transparent;
	color: #818E94;
	-moz-appearance: none;
	/* for Chrome */
	-webkit-appearance: none;
}
/* Organization Detial page Tab 4 of Logs Css start */


/* varification ein text editor code */
.MUIRichTextEditor-toolbar-13 {
	border-bottom: 1px solid #D3D8DA;
}

.notranslate.public-DraftEditor-content, .MUIRichTextEditor-editorContainer-5.MUIRichTextEditor-placeHolder-9 {
	padding: 20px;
	color: #4D4D4D;
	
}

/* NavBar data */

.makeStyles-withBtnDrawerPaper-78 {
	min-height: 995px;
}