@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.detailsContainer {
	border: 1px solid #E8E9E9;
	box-shadow: 0px 2px 4px #0000001A;
	font-family: 'Roboto', sans-serif;
}

.detailsHeader {
    padding: 0px 30px;
    border-bottom: 1px solid #E8E9E9;
    height: 60px;
    display: flex;
    align-items: center;
}

.detailsHeaderTitle {
	color: #5A5A5A;
	display: inline-block;
    font-weight: 300;
	margin: 0;
	font-size: 24px;

}

.labelsContent {
	display: inline-block;
	vertical-align: super;
	margin-left: 10px;
}

.detailsLabel {
	text-transform: uppercase;
	border-radius: 20px;
	padding: 8px 15px;
	font-size: 14px;
	font-weight: 500;
}

.detailsLabel.approved {
	color: #3CC480;
	background: #EFFBF5;
	margin-left: 5px;
}

.detailsLabel.onboarding {
	color: #EEB000;
	background: #FEF9EB;
	margin-left: 5px;

}

.detailsLabel.blocked {
	color: #C44058;
	background: #F9EBED;
	margin-left: 5px;

}

.detailsListingContainer {
	padding: 20px 30px;
	display: flex;
    justify-content: space-between;
}

.detailsMainContent {
	display: inline-block;
	width: 330px;
}

.detailsListings, .listingTitle, .listingPlainText {
	display: inline-block;
}

.detailsListings {
	width: 330px;
	margin-bottom: 15px;
	display: flex;
}

.detailsListings .listingTitle {
	margin: 0;
	float: left;
	color: #818E94;
	font-size: 14px;
	font-family: Open Sans;
	font-weight: 400;
	width: 120px;
} 

.detailsListings .listingPlainText {
	color: #4D4D4D;
	font-size: 14px;
	font-family: Open Sans;
	font-weight: 600;
	margin: 0;
}
.detailsListings .listingPlainText br{
	display: none;
}

.listCheckImg {
	width: 14px;
}

.detailsListingFooter {
	border-top: 1px solid #E8E9E9;
	padding: 20px 30px;
    text-align: right;
}

.detailsListingFooter a {
	color: #2490F9;
	font-family: Roboto;
	font-weight: 500;
	font-size: 14px;
	text-decoration: none;
}


.makeStyles-class15-342 {
	padding: 16px !important;
}



/* Organization Detial page Tab 4 of Logs Css start */
.agreements-container {
	border: 1px solid #E8E9E9;
	box-shadow: 0px 2px 4px #0000001A;
	font-family: 'Roboto', sans-serif;
}

.agreement-title {
	padding: 15px 30px;
	border-bottom: 1px solid #E8E9E9;
}

.agreement-title h1 {
	color: #5A5A5A;
	font-family: Roboto;
	font-size: 24px;
	margin: 0;
	font-weight: 400;
}

.agreement-table {
	width: 100%;
	background: #F7F8FA;
	overflow-x: auto;
}

.agreement-table th {
	text-align: left;
	color: #818E94;
	font-family: Roboto;
	font-size: 14px;
	padding: 20px 0px 20px 30px;
	font-weight: 400;
	border-bottom: 1px solid #E8E9E9;
}

.agreement-table-head {
	border: 1px solid #D3D8DA;
}

.agreement-table-rows {
	background: #fff;
}

.agreement-table-rows td {
	padding: 20px 30px;
	border-bottom: 1px solid #E8E9E9;

}

.agreement-footer td {
	padding: 20px 30px;
	text-align: right;
}

.arrow-down {
	vertical-align: inherit;
	margin-left: -15px;

}

.MuiSvgIcon-root {
	width: 24px;
	height: 24px;
	vertical-align: middle;
}

#left-arrow {
	fill:#d3d8da;
}

#right-arrow {
	fill:#bbbbbb;
}

.pagination-img {
	vertical-align: middle;
	margin: 0 10px;
}

.no-of-rows {
	color: #818E94;
	font-size: 14px;
}

.pagination {
	color: #818E94;
	font-size: 14px;
}

.left-right-icon {
	margin: 0 10px;
}

.agreement-footer {
	background: #fff;
}

.paginationNo {
	border: none;
	background: transparent;
	color: #818E94;
	-moz-appearance: none;
	/* for Chrome */
	-webkit-appearance: none;
}
/* Organization Detial page Tab 4 of Logs Css start */


/* varification ein text editor code */
.MUIRichTextEditor-toolbar-13 {
	border-bottom: 1px solid #D3D8DA;
}

.notranslate.public-DraftEditor-content, .MUIRichTextEditor-editorContainer-5.MUIRichTextEditor-placeHolder-9 {
	padding: 20px;
	color: #4D4D4D;
	
}

/* NavBar data */

.makeStyles-withBtnDrawerPaper-78 {
	min-height: 995px;
}

.Header_logo_main__3q_hF{
	width: 300px;
}

.MuiAlert-action {
    height: 40px !important;
}

#root > section > header > div > div > div > div > div > div {
    height: 40px !important;

}

.MuiPaper-elevation0 section {
    width: 100% !important;
}

.addOrganizationTabs .MuiPaper-elevation0{
    padding: 16px 0px !important;
    
}

.filter button span{
    text-transform: uppercase;
    font-weight: 500;
}

.filter button:hover{
    background: none;
}

.button_text{
    font-size: 14px !important;
    font-weight: 500;
}

.MuiButton-startIcon {
    display: inherit !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.table .status {
    text-transform: capitalize;
}

/* button.MuiTab-root.MuiTab-textColorInherit {
    width: 50% !important;
}

.MuiTabs-indicator {
    width: 50% !important;
} */
.invite_card__3ICr9 {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.invite_headingDiv__30ZFE {
    border-bottom: 1px dashed #D3D8DA;
}

.invite_headingInvite__11W-s{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.invite_bottonDiv__3ItSS {
    float: right;
    margin-top: 65px;
}

.invite_orgForm3__OBL03 .invite_accordian__3J62O {
    margin-left: 0;
    width: 100% !important;
}

.MuiExpansionPanel-root.Mui-expanded {
    margin: 0 !important;
    border-top: 1px solid #E8E9E9;
    border-bottom: 1px solid #E8E9E9;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.react-tel-input .country-list .flag {
    position: unset !important;
  }

  .react-tel-input .form-control:focus {
    /* border-color: transparent !important; */
    box-shadow: none !important;
  }

  .react-tel-input .country-list {
      width: 275px !important;
  }
.verified-icon {
    position: absolute;
    margin-left: 5px;
    width: 20px;
}

.organizationDetail_MuiPaper-elevation0__2vc33 section {
    width: 100% !important;
}

.organizationDetail_routes__18Blg  header {
    padding: 16px 0 !important;
}


/* button.MuiTab-root.MuiTab-textColorInherit {
    width: 50% !important;
}

.MuiTabs-indicator {
    width: 50% !important;
} */
.card {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.headingDiv {
    border-bottom: 1px dashed #D3D8DA;
}

.headingInvite{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.bottonDiv {
    float: right;
    margin-top: 65px;
}

.field_box .react-tel-input .form-control {
    width: 100% !important;
  }


  .react-tel-input .country-list .flag {
    position: unset !important;
  }

  .react-tel-input .form-control:focus {
    /* border-color: transparent !important; */
    box-shadow: none !important;
  }

  .react-tel-input .country-list {
      width: 275px !important;
  }

  .orgDetailsContainer .second-row{
    margin-top: 0 !important;
  }
.add_card__jmBLa {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.add_headingDiv__2TFQV {
    border-bottom: 1px dashed #D3D8DA;
}

.add_headingInvite__1YMm9{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.add_headingLeftSidebar__Q-dwA
{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 22px;
    letter-spacing: 0px;
    opacity: 1;
}

.add_bottonDiv__1uBYj {
    float: right;
    margin-top: 65px;
    height: auto;
    display: block;
}

.add_bottonDiv2__3Odn0{
    margin-top: 620px;
    padding-top: 40px;
    border-top: 1px dashed #D3D8DA;
    height: auto;
    display: block;
    position: relative;
}


.add_roletable__2geEu > div > div{
    display: none !important;
}

.add_roletable__2geEu > div{
    background: transparent;
}

.add_columnsClasses__uu5eb {
    padding: 16px !important;
}


.add_addtionText__2n5vl {
    color: #2490F9;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.add_memberConsole__16RxJ {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 40px;
}


.add_allowMemberText__3EukE {
    margin: 0px;
    color: #818E94;
    letter-spacing: 0px;
}

.add_addAdditionalRole__366bC{
    display: inline-block;
    width: 400px;
    float: right;
}

.add_routes__1xiLQ {
    padding-top: 30px;
    padding-left: 70px;
    height: 965px;
    width: 100%;
    display: flex;
  }


.varifyEin_card__3L-qn {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.varifyEin_headingDiv__19AFn {
    border-bottom: 1px dashed #D3D8DA;
}

.varifyEin_headingInvite__1EgDp{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.varifyEin_bottonDiv__2IKQj {
    float: right;
    margin-top: 65px;
    margin-bottom: 170px;
}

.varifyEin_fileSelector__1Mvum {
    margin-top: 40px;
}

.varifyEin_fileSelector__1Mvum ul{
    padding-top: unset !important;
    padding-bottom: unset !important;
}

.varifyEin_editorOuter__FMC43 {
    min-height: 170px;
    border: 1px solid #D3D8DA;
}

.varifyEin_editorOuter__FMC43 > .varifyEin_MUIRichTextEditor-toolbar-13__AArr7 {
    border-bottom: 1px solid #D3D8DA;
}

.varifyEin_TextArea__2u6aL .varifyEin_text-editor__2VBQK .varifyEin_editor_tool__2gtuY{
    display: none;
  }


.varifyEin_card__3ciTv {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.varifyEin_headingDiv__1hrWh {
    border-bottom: 1px dashed #D3D8DA;
}

.varifyEin_headingInvite__3cXFk{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.varifyEin_bottonDiv__-qhS1 {
    float: right;
    margin-top: 65px;
}

.varifyEin_fileSelector__2MYsY {
    margin-top: 40px;
}

.varifyEin_fileSelector__2MYsY ul{
    padding-top: unset !important;
    padding-bottom: unset !important;
}


.varifyEin_editorOuter__1lf37 {
    min-height: 170px;
    border: 1px solid #D3D8DA;
}

.varifyEin_editorOuter__1lf37 > .varifyEin_MUIRichTextEditor-toolbar-13__30T3D {
    border-bottom: 1px solid #D3D8DA;
}



/* 
#root > section > section > section > div > section > section > div > div > div:nth-child(2) > div > table > div > div > tbody > tr > th:nth-child(2) > div {
    background: transparent !important;
}

#root > div > section > div > div > div > div.MuiPaper-root.MuiTableContainer-root.table.MuiPaper-elevation0.MuiPaper-rounded > table > tbody > tr > th:nth-child(2) > div > p {
    text-align: left !important;
}


#root > section > section > section > div > div > div > div > table > div > div > tbody > tr > th > div {
    background: transparent !important;
}

#root > div > section > div > div > div > div > div > table > tbody > tr > th:nth-child(4) > div > p {
    text-align: left !important;
} */
.addHardware_card__2Mn9N {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.addHardware_headingDiv__UiCXV {
    border-bottom: 1px dashed #D3D8DA;
}

.addHardware_headingInvite__bZdb9{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.addHardware_bottonDiv__3Pmj- {
    float: right;
    margin-top: 65px;
}


.editHardware_card__38Iw_ {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.editHardware_headingDiv__2r5h- {
    border-bottom: 1px dashed #D3D8DA;
}

.editHardware_headingInvite__2XKfW{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.editHardware_bottonDiv__2By2b {
    float: right;
    margin-top: 65px;
}


.editHardware_card__3seJO {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.editHardware_headingDiv__136Aj {
    border-bottom: 1px dashed #D3D8DA;
}

.editHardware_headingInvite__3KQWm{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.editHardware_secondheading__1yK9Q{
    color:#5A5A5A;
    font-family: Roboto;
    font-weight: 300;
    font-size: 22px;
    letter-spacing: 0px;
    opacity: 1;
    margin-bottom: 0;
}

.editHardware_bottonDiv__qXpAA {
    float: right;
    margin-top: 65px;
}

.owner-crown svg {
    width: 15px;
background: #eee;
padding: 4px;
border-radius: 50%;
height: 16px;
position: relative;
    top: 7px;
    margin-left: 2px;
}

.drawer_list_container.card {
    width: unset !important;
    height: unset !important;
    position: unset !important;
}


.border_top_bottm.form-switch-tooltip .MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: #4d4d4d !important;
    padding: 10px !important;
    position: relative !important;
}

.border_top_bottm.form-switch-tooltip .MuiTooltip-popper {
    left: -18px !important;
    top: 10px !important;
}

.edit_profile_photo{
  text-align: center;
  margin-top: 110px;
}

.edit_profile_photo div{
  width: auto !important;
  height: auto !important;
}

.edit_profile_photo div img{
  width: 170px !important;
  height: 170px;
}

.initials-wrapper  {
  margin-bottom: 10px !important; 
}

.initials-wrapper div {
  margin: 0 auto !important; 
  width: 170px !important;
  height: 170px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials-wrapper div p{
  padding-top:0 !important;

}

.border_line{
  border-bottom: #E8E9E9 1px solid;
}

.page_footer {
  border-top: #E8E9E9 1px solid;
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer_btn button{
  background: transparent !important;
  color: #2490F9 !important;
  padding: 0 !important;
  margin-left:15px;
}

.footer_btn button:hover{
  background: transparent !important;
  color: #2490F9 !important;
}


.page_body {
  background: #fff;
  border: #E8E9E9 1px solid;
}

.page_form_title {
  color: #5A5A5A;
  font-size: 24px !important;
  padding: 0px 30px;
  border-bottom: #E8E9E9 1px solid;
  width: auto !important;
  font-weight: 300 !important;
  display: flex;
  align-items: center;
  height: 60px;
}

.form_body {
  padding: 30px;
}
.btnCencel button {
  background: none !important;
  min-width: inherit !important;
  margin-right: 7px;
}

.btnCencel button span {
  color: #4d4d4d !important;
  text-transform: uppercase !important;
}

.buttons {
  text-align: right;
  padding-top: 28px;
}

.backBtn {
  left: 70px !important;
  margin-top: 30px !important;
}

.adminProfile_container .base-text-field .MuiInputAdornment-root svg{
  display: none;
}

.remove_photo{
  font-size: 14px;
  color: #4D4D4D;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}
.signin_signInClass__GPD_8 {
    width: 100% !important;
    margin: auto;
    border: unset;
    background-image: url(/static/media/khushbo_background.570f1f71.png);

}

.signin_signInClass__GPD_8 div div div{
    border:none !important;
    background-repeat: no-repeat;
    /* background: none !important; */
    /* background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FFFFFFDA 63%,
     #FFFFFF00 100%) 0% 0% no-repeat padding-box !important; */
     
    
}
 
.signin_signInClass__GPD_8 div div div svg{
    top: 0;
}

.signin_signInClass__GPD_8 section h1 {
    font-family: Roboto !important;
    color:#5A5A5A !important;
    font-weight: 400;
    font-size: 30px !important;
}

main section form section div:nth-child(2) label span:nth-child(2) p {
    font-family: Roboto !important;
    color:#D3D8DA !important;
    font-size: 14px !important;
}
/* .signInClass section p {
    font-family: Roboto !important;
    color:#D3D8DA !important;
    font-size: 14px !important;
} */



/* .signInClass div > main{
    width: auto !important;

} */

.RequestTableContainer{
    margin-top:60px;
}

.RequestTableContainer .MuiExpansionPanelDetails-root {
    display: flex;
    padding: 8px 56px 8px;
}

.RequestTableContainer .accDate {
    width: 15% !important;
}

.RequestTableContainer .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 10px 0;
}

.RequestTableContainer .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 30px;
}

.cencelButton span{
    color: rgb(77, 77, 77);
}
.RequestTableContainer{
    margin-top:60px;
}

.RequestTableContainer .MuiExpansionPanelDetails-root {
    display: flex;
    padding: 8px 56px 8px;
}

.RequestTableContainer .accDate {
    width: 15% !important;
}

.RequestTableContainer .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 10px 0;
}

.RequestTableContainer .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 30px;
}

.cencelButton span{
    color: rgb(77, 77, 77);
}

.detailsTableContainer {
    margin-top: 64px;
}

.TextArea .text-editor .editor_tool{
    display: none;
  }
.RequestTableContainer{
    margin-top:60px;
}

.RequestTableContainer .MuiExpansionPanelDetails-root {
    display: flex;
    padding: 8px 56px 8px;
}

.RequestTableContainer .accDate {
    width: 15% !important;
}

.RequestTableContainer .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 10px 0;
}

.RequestTableContainer .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 30px;
}

.cencelButton span{
    color: rgb(77, 77, 77);
}

.detailsTableContainer {
    margin-top: 64px;
}

.TextArea .text-editor .editor_tool{
    display: none;
  }

.MuiPaper-elevation0 section {
    width: 100% !important;
}

.addOrganizationTabs .MuiPaper-elevation0{
    padding: 16px 0px !important;
    
}

.filter button span{
    text-transform: uppercase;
    font-weight: 500;
}

.filter button:hover{
    background: none;
}

.button_text{
    font-size: 14px !important;
    font-weight: 500;
}

.MuiButton-startIcon {
    display: inherit !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.table .status {
    text-transform: capitalize;
}

/* button.MuiTab-root.MuiTab-textColorInherit {
    width: 50% !important;
}

.MuiTabs-indicator {
    width: 50% !important;
} */

.sticky-proceed-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: #E4E8EC 1px solid;
    left: 0;
    padding: 15px 40px;
}

.noBorder {
    border: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.formContainerHikmah .noBorder {
    border: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

@media (max-width:1480px) {
    .sticky-proceed-footer {
        width:82%;
    }
}

.proceed-btn{
	height: 35px;
	width: auto;
    background-color: #2490F9 !important;
    border-radius: 25px !important;
    margin-left: 16px !important;
    margin-right: 100px !important;
}

.cancel-next-btn {
	display: flex;
	justify-content: flex-end;
}



.title-top-section {
    text-align: left !important;
    font-family: "Roboto";
    font-size: 28px;
    color: #4d4d4d;
    text-transform: capitalize;
    margin: 0 !important;
    border-bottom: 0 !important;
    font-weight: 300;
    padding-bottom: 0px !important;
}

.field_box.feature-table .tableBody .tableScroll {
    width: 100% !important;
}

.short-description {
    margin-bottom: 20px !important;
}
.short-description .MuiOutlinedInput-root{
    border-radius: 0 !important;
}

.short-description .description-label {
    color: #818E94;
    font-size: 12px;
    margin-bottom: 2px;
}

.description-count {
    color: #B6B9B9;
    font-size: 12px;
    position: relative;
    top: 7px;
    display: flex;
    justify-content: flex-end;
}

.short-description .MuiOutlinedInput-root.MuiInputBase-formControl {
    width: 100%;
}

.hardware-switch {
    box-shadow: 0 0 4px lightgrey;
    margin: 10px 0;
    padding: 10px;
}


.hardware-switch .FormSwitch h6 {
    margin-top: 5px;
}

.hardware-switch .drawer_list_container.card.FormSwitch div {
    margin-bottom: 0 !important;
}

.input-fields-bottom-line {
    border-bottom: #D3D8DA dashed 1.5px;
}

.disabledStepper {
    background-color: lightgray !important;
}

.side-drawer__content .MuiPaper-outlined {
    border: 0 !important;
    padding: 0 7px;
}

.field_box .options::before {
    border-bottom: 1px solid #D3D8DA !important;
}

.formContainerHikmah{
    width: 570px;
    margin: 0 auto;
}

.bottomSpacing{
    margin-bottom: 150px;
}

.feature-table .MuiTableCell-root.MuiTableCell-body div > img:nth-child(2) {
    width: 0px !important;
}

.plan-listing-table div > img:nth-child(2) {
    width: 0px !important;
}

/* #root > section > section > section > section > section > div > div > div:nth-child(2) > div > p > div > div > table > div > div > tbody  > th:nth-child(6) > div > img:nth-child(2) {
    width: 0px !important;

} */
.edit_profile_photo{
  text-align: center;
  margin-top: 110px;
}

.edit_profile_photo div{
  width: auto !important;
  height: auto !important;
}

.edit_profile_photo div img{
  width: 170px !important;
  height: 170px;
}

.initials-wrapper  {
  margin-bottom: 10px !important; 
}

.initials-wrapper div {
  margin: 0 auto !important; 
  width: 170px !important;
  height: 170px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials-wrapper div p{
  padding-top:0 !important;

}

.border_line{
  border-bottom: #E8E9E9 1px solid;
}

.page_footer {
  border-top: #E8E9E9 1px solid;
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer_btn button{
  background: transparent !important;
  color: #2490F9 !important;
  padding: 0 !important;
  margin-left:15px;
}

.footer_btn button:hover{
  background: transparent !important;
  color: #2490F9 !important;
}


.page_body {
  background: #fff;
  border: #E8E9E9 1px solid;
}

.page_form_title {
  color: #5A5A5A;
  font-size: 24px !important;
  padding: 0px 30px;
  border-bottom: #E8E9E9 1px solid;
  width: auto !important;
  font-weight: 300 !important;
  display: flex;
  align-items: center;
  height: 60px;
}

.form_body {
  padding: 30px;
}
.btnCencel button {
  background: none !important;
  min-width: inherit !important;
  margin-right: 7px;
}

.btnCencel button span {
  color: #4d4d4d !important;
  text-transform: uppercase !important;
}

.buttons {
  text-align: right;
  padding-top: 28px;
}

.backBtn {
  left: 70px !important;
  margin-top: 30px !important;
}

.adminProfile_container .base-text-field .MuiInputAdornment-root svg{
  display: none;
}

.remove_photo{
  font-size: 14px;
  color: #4D4D4D;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

.BaseImgChipField {
  padding-top: 20px;
}
.BaseImgChipField label{
  position: relative;
  top: -6px;
}

.RedrioGroupContainer .MuiFormControlLabel-label{
  font-weight: 600;
  color: #4D4D4D;
  font-size: 14px;
}

.side-drawer__container .MuiButton-containedPrimary {
  color: #fff !important;
  background-color: #2490F9 !important;
}
.edit_profile_photo{
  text-align: center;
  margin-top: 110px;
}

.edit_profile_photo div{
  width: auto !important;
  height: auto !important;
}

.edit_profile_photo div img{
  width: 170px !important;
  height: 170px;
}

.initials-wrapper  {
  margin-bottom: 10px !important; 
}

.initials-wrapper div {
  margin: 0 auto !important; 
  width: 170px !important;
  height: 170px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials-wrapper div p{
  padding-top:0 !important;

}

.border_line{
  border-bottom: #E8E9E9 1px solid;
}

.page_footer {
  border-top: #E8E9E9 1px solid;
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer_btn button{
  background: transparent !important;
  color: #2490F9 !important;
  padding: 0 !important;
  margin-left:15px;
}

.footer_btn button:hover{
  background: transparent !important;
  color: #2490F9 !important;
}


.page_body {
  background: #fff;
  border: #E8E9E9 1px solid;
}

.page_form_title {
  color: #5A5A5A;
  font-size: 24px !important;
  padding: 0px 30px;
  border-bottom: #E8E9E9 1px solid;
  width: auto !important;
  font-weight: 300 !important;
  display: flex;
  align-items: center;
  height: 60px;
}

.form_body {
  padding: 30px;
}
.btnCencel button {
  background: none !important;
  min-width: inherit !important;
  margin-right: 7px;
}

.btnCencel button span {
  color: #4d4d4d !important;
  text-transform: uppercase !important;
}

.buttons {
  text-align: right;
  padding-top: 28px;
}

.backBtn {
  left: 70px !important;
  margin-top: 30px !important;
}

.adminProfile_container .base-text-field .MuiInputAdornment-root svg{
  display: none;
}

.remove_photo{
  font-size: 14px;
  color: #4D4D4D;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

.BaseImgChipField {
  padding-top: 20px;
}
.BaseImgChipField label{
  position: relative;
  top: -6px;
}

.AppPageContainer{
  margin-top: 0px;
}

.AppPageContainer .your_cart_title{
  color: #4C5E67;
  font-size: 16px;
  margin-bottom: 4px;
}

.AppPageContainer .tableCont {
  background: none !important;
}
.AppPageContainer .tableTd{
  background: none !important;
}

.AppPageContainer .tableCont div{
  background: none !important;
}
.OrgDetailsContainer{
    margin-top: 70px;
}
.OrgLogoHeaderContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DetailsTitleContainer{
    display: flex;
}

.OrgLogocol{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #00000014;
    border-radius: 100px;
    opacity: 1;
    width: 50px;
    height: 50px;
    padding: 10px;

}

.OrgLogocol img{
    width: 100%;
}

.OrgDetailsTitle {
    margin-left: 10px;
    display: flex;
    align-items: center;
}
.OrgDetailsTitle h3{
    color: #4D4D4D;
    font-size: 20px;
    margin: 0;
}

.OrgDetailsTitle h3 p{
    color: #818E94;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
}


.FormButtonContainer {
    display: flex;
    justify-content: space-between;
}

.FormButtonContainer .downloadBtn {
    border-radius: 100px;
    font-size: 14px;
    color: #4d4d4d;
    text-transform: uppercase;
    letter-spacing: 1.26px;
    font-weight: 600;
    border: 1px solid #e8e9e9;
}

.ReceiptsContainer{
    max-width: 970px;
    margin: 0 auto;
    position: relative;
  }
  
  .ReceiptsForm{
    min-height: 400px;
    background: #fff;
    border:#E8E9E9 1px solid;
    margin-top: 15px;
    padding: 50px 20px;
  }
  
  .ReceiptsContainer .backBtn{
    margin-top: 0 !important;
    left: 0 !important;
    position: relative !important;
  }
  
  .ReceiptsForm .RcsptHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0px 64px 0 31px;
  }
  
  .ReceiptsForm .DcLogoRcspt {
    width: 190px;
    object-fit: contain;
    position: relative;
    top: 15px;
}
  .ReceiptsForm .RcsptHeaderInfo h2{
    font-size: 20px;
    color: #4D4D4D;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 6px;
  }
  
  .ReceiptsForm .RcsptHeaderInfo p{
    font-size: 16px;
    color: #818E94;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
  }
  
  .ReceiptsForm .RcsptHeaderInfo p span{
    color: #4D4D4D;
    font-weight: 500;
  
  }
  
  .ReceiptsForm .RcsptHeaderInfo p label{
    color: #3CC480;
    font-weight: 600 !important;
  
  }
  
  .BillInfromation{
    background: #F7F8FA;
    padding: 30px;
    margin-top:30px;
  }
  
  .BillInfromation{}
  
  .BillInfromation .RcsptHeaderInfoDetails h2{
    font-size: 14px;
    color: #818E94;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    margin-bottom: 6px;
    text-transform: uppercase;
  }
  
  .BillInfromation .RcsptHeaderInfoDetails h3{
    font-size: 20px;
    color: #4D4D4D;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    margin-bottom: 6px;
    letter-spacing: 0;
  }
  
  .BillInfromation .RcsptHeaderInfoDetails p{
    font-size: 16px;
    color: #4D4D4D;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    letter-spacing: 0;
  }
  
  .RcptBiilingTable{
    padding: 30px;
  } 
  .RcptBiilingTable .MuiPaper-elevation1{
    box-shadow: none !important;
  }
  
  .RcptBiilingTable .MuiTableRow-head .MuiTableCell-root{
    font-size:14px;
    font-weight: 500;
    color: #818E94;
    padding: 0px 0 !important;
    text-transform: uppercase;
  }
  
  .RcptBiilingTable .MuiTableBody-root .MuiTableCell-root {
    font-size: 14px;
    font-weight: 500;
    color: #4D4D4D;
    padding: 16px 0 !important;
  }
  
  .TotalDetailsContainer{
    padding: 0 30px;
  }
  
  .TotalDetails {
    text-align: right;
    width: 292px;
    float: right;
}
  
  .TotalDetails p{
    font-size: 14px;
    color: #818E94;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    letter-spacing: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  
  .TotalDetails .boldText{
    font-weight: 600;
    color:#4D4D4D;
  }
  
  .RscptFormFooter{
    border-top:#E8E9E9 1px solid;
    margin-top: 338px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
  }
  
  .RscptFormFooter p{
    font-size: 16px;
    color: #818E94;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    letter-spacing: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 2.24px;
  }

  .invoiceAmountText{
    text-align: left;
  }

.MuiPaper-elevation0 section {
    width: 100% !important;
}

.addOrganizationTabs .MuiPaper-elevation0{
    padding: 16px 0px !important;
    
}

.filter button span{
    text-transform: uppercase;
    font-weight: 500;
}

.filter button:hover{
    background: none;
}

.button_text{
    font-size: 14px !important;
    font-weight: 500;
}

.MuiButton-startIcon {
    display: inherit !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.table .status {
    text-transform: capitalize;
}

/* button.MuiTab-root.MuiTab-textColorInherit {
    width: 50% !important;
}

.MuiTabs-indicator {
    width: 50% !important;
} */
.HikmahSteperContainer {
    padding-top: 31px;
}

.HikmahSteperContainer .MuiStepper-root {
    width: 70% !important;
}

.HikmahSteperContainer .MuiStepConnector-root {
    width: auto !important;
}

.HikmahSteperContainer .StepperFormBdy {
    max-width: 570px;
    margin: 0 auto;
}

.StepperFormContainer {
    border-top: #D3D8DA 1px dashed;
    max-width: 80%;
    margin: 0 auto;
    padding-top: 30px;
    margin-top: 30px;
    margin-bottom: 100px;
}

.org_logo img {
    width: 24px;
    margin-right: 8px;

}

.org_logo {
    display: flex;
    align-items: center;
}

.enablePlan {
    display: flex;
    align-items: center;
}

.enablePlan svg {
    font-size: 24px;
    margin-right: 8px;
    color: #2490F9;

}

.disablePlan {
    display: flex;
    align-items: center;
}

.disablePlan svg {
    font-size: 24px;
    margin-right: 8px;
    color: #A4A7B0;

}


.Accr_Body {
    margin-left: 33%;
}

.StepperFormContainer .MuiAccordionDetails-root {
    display: block;
    padding: 8px 16px 16px;
}

.stepperTitle {
    color: #5A5A5A !important;
    font-weight: 300 !important;
}

.ModalForm .MuiOutlinedInput-adornedEnd {
    padding-right: 14px;
    width: 100%;
}

.formInfoSection {
    display: flex;
    color: #818E94;
    font-size: 14px;
    line-height: 20px;
}

.formInfoSection p {
    color: #818E94 !important;
    font-size: 14px !important;
    line-height: 23px !important;
}

.formInfoSection svg {
    color: #A4A7B0;
    margin-right: 8px;
}

.ModalFour .ModalFourMdlBody {
    padding: 32px 30px;
    overflow-y: auto;
}

.ModalFour .SelectFieldOne .MuiSelect-selectMenu {
    height: 48px;
}

.ModalFour .input_container .MuiOutlinedInput-input {
    height: 48px;
}

.OnboardFormButton {
    height: 48px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 4px #0000001A;
    display: flex;
    justify-content: end;
    padding: 20px;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
}

.OnboardFormButtonCtn{
    padding-right: 7%;
    display: flex;
}

.OrgypeField div{
    min-width: 100%;
 }
.signin_signInClass__2cJ_y {
    width: 100% !important;
    margin: auto;
    border: unset;
    background-image: url(/static/media/khushbo_background.570f1f71.png);

}

.signin_signInClass__2cJ_y div div div{
    border:none !important;
    background-repeat: no-repeat;
    /* background: none !important; */
    /* background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FFFFFFDA 63%,
     #FFFFFF00 100%) 0% 0% no-repeat padding-box !important; */
     
    
}
 
.signin_signInClass__2cJ_y div div div svg{
    top: 0;
}

.signin_signInClass__2cJ_y form div {
    background: none !important;
    background: #fff;
}

.signin_signInClass__2cJ_y section h1 {
    font-family: Roboto !important;
    color:#5A5A5A !important;
    font-weight: 400;
    font-size: 30px !important;
}

main section form section div:nth-child(2) label span:nth-child(2) p {
    font-family: Roboto !important;
    color:#D3D8DA !important;
    font-size: 14px !important;
}
/* .signInClass section p {
    font-family: Roboto !important;
    color:#D3D8DA !important;
    font-size: 14px !important;
} */



/* .signInClass div > main{
    width: auto !important;

} */

.otp_otpContainer__RpNWJ {
    background-image: url(/static/media/khushbo_background.570f1f71.png);
}

.otp_otpContainer__RpNWJ div{
    
    

}

.otp_otpContainer__RpNWJ > div > div > div{
    border: none;
}

.otp_otpContainer__RpNWJ article {
    font-family: Roboto;
    font-size: 30px;
    color: #5A5A5A;
    font-weight: 300;
}

input:focus {
    outline: none;
}

.otp_header_inner_alert__16Z7a{display: none;}


.accordion {
    width: 100% !important;
    margin-left: 0 !important;
}

.head {
    margin-top: 0;
}

.field_box {
    min-height: 55px;
    margin-bottom: 0;
}

.field_box .MuiInput-formControl{
    width: 100%;
    

}

.field_box .MuiInput-underline:before{
    border-bottom: 1px solid #D3D8DA;
}

.field_box .MuiFormControl-root {
    margin: 0 !important;
    width: 100%;
    max-width: initial;
    min-width: initial;
    display: block;
}

.form_button {
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
}

.backBtnFrom button {
    width: 89px !important;
    height: 36px !important;
    border: 1px solid #e8e9e9;
    border-radius: 18px !important;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    padding: 0;
    align-self: center;
    position: absolute;
    left: 70px;
    background-color: transparent;
    box-shadow: none;
    margin-top: 30px !important;
    min-width: initial !important;
    padding-right: 5px;
}

.backBtnFrom button:hover{
    border: 1px solid #e8e9e9;
    background-color: transparent;
    box-shadow: none;

}

.backBtnFrom button span {
    font-size: 14px;
    letter-spacing: 1.26px;
    color: #4d4d4d;
}

.title_top {
    text-align: center;
    font-family: "Roboto";
    font-size: 28px;
    color: #4d4d4d;
    text-transform: capitalize;
    font-weight: 300;
    margin: 0 0 15px!important;
    border-bottom: #D3D8DA dashed 1.5px;
    padding-bottom: 30px;
}

.border_bottom{
    border-bottom: #D3D8DA 1px solid;
}

.border_top_bottm {
    border-top: 1px solid #d3d8da;
    border-bottom: 1px solid #d3d8da;
    padding: 15px 0;
}

.userRollBox{
    display: block;
    width: 100%;
    padding: 0 20px;
}

.sub_title{
    font-size: 22px;
    letter-spacing: 0;
    color: #4d4d4d;
    text-transform: capitalize;
    opacity: .8;
    font-weight: 300;
    text-align: left;
    margin: 0!important;
}

 .chip_list {
    width: 100% !important;
}

.opt_container .MuiPaper-outlined {
    border: none !important;
}

.orgTitle span {
    margin-left: 0px;
}

.text-editor .text-box p {
    color: #4d4d4d !important;
}

.text-editor .text-box {
color: #4d4d4d !important;
}

.errorMessage {
    position: absolute;
    top: -68px;
    z-index: 99999;
    max-width: 760px;
    left: 69px;
    width: 100%;
}

.ondisableText{
    font-weight: 100;
    color: #818e94;
  }

  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    padding: 0 0 0 0 !important;
    border-radius: 3px 0 0 3px;
}

.middle-section-white{
    padding-bottom: 30px;
}

.accordionContainerD .MuiCollapse-container {
    width: 93%;
    margin-left: 40px;
    position: relative;
    top: 0;
    background-color: transparent;
}

.header-nav{
    min-height: auto !important;
}

.middle-section {
    margin-top: 77px !important;
}

.DetailsHeaderContainer .DtlInfoCol .DtlInfoColSubTitle {
    text-transform: capitalize;
}

.header_alert {
    z-index: 9999 !important;
    width: 60% !important;
    position: fixed !important;
    top: 10px !important;
    /* margin-left: 68px !important; */
}

.header_alert .MuiAlert-root {
    height: 38px;
}

.MuiExpansionPanelSummary-content{
    cursor: pointer;
}

.fieldFullwidth div div {
    width: 100%;
    margin: 8 0 !important;
}

.main-footer p svg{
    top: -1px;
}

.card_details_table .col_td p {
    margin: 0;
    word-break: break-word;
}

.PhoneInpurContainer .underlineWithFlag .react-tel-input .special-label{
    font-family: 'Roboto', sans-serif;
}

.StepperTableBdy .MuiAccordionSummary-root {
    display: flex !important;
}

.ModalFourFooter .MsInputButtonMainContainer .MsInputButton{
    background: rgb(36, 144, 249)  !important;
}

.input_container .MuiOutlinedInput-notchedOutline {
    width: 100% !important;
}

.MuiOutlinedInput-notchedOutline {
    width: 100% !important;
}

.StepperTableBdy .CheckoutPaymentForm .SelectFieldContainer label {
    height: 24px;
}

.input_container .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(36, 144, 249)  !important;
}

.MuiAccordionActions-root button:last-child span{
    color: rgb(36, 144, 249)  !important;
}

/* ====== OTP MODAL CSS ======= */

.OtpModalContainer{}

.OtpModalInner{
    display: flex;
    align-items: center;
    justify-content: center;
}

.OtpModalBody {
    position: absolute;
    width: 540px;
    height: 350px;
    background: #fff;
    border-radius: 4px;
}

.OtpModalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    border-bottom: #dfdfdf 1px solid;
}

.OtpModalTitle h2{
    color: #676C7B;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.OtpModalTitle .closeIcon{
    color: #9593b3;
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 11px;

  }

  .OtpModalFooter{
    display: flex;
    justify-content: end;
    background: #F7F9FA;
    border-top: #E4E8EC 1px solid;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 0;
  }

  .OtpModalFooter .MuiButtonBase-root{
    margin-right: 15px;
  }

  .OtpModalFooter .MuiButtonBase-root{
    margin-right: 15px;
    height: 38px;
    box-shadow: none;
    font-size: 14px;
  }

  .OtpModalFooter .btLight{
    color: #676C7B;
    background: #fff; 
    border: #E4E8EC 1px solid;
  }

  .OtpModalFooter .btLight:hover{
    color: #676C7B;
    background: #fff; 
    border: #E4E8EC 1px solid;
  } 
  .OtpModalFooter .btnBlue{
    color: #fff;
    background: #2490F9; 
    border: #2490F9 1px solid;
  }

  .OtpModalFooter .btnBlue:hover{
    color: #fff;
    background: #2490F9; 
    border: #2490F9 1px solid;
  }

  .ModalOtpForm{
    padding: 15px;
  }

  .ModalOtpForm .MuiPaper-outlined {
    border: none;
}


