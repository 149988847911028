.drawer_list_container.card {
    width: unset !important;
    height: unset !important;
    position: unset !important;
}


.border_top_bottm.form-switch-tooltip .MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: #4d4d4d !important;
    padding: 10px !important;
    position: relative !important;
}

.border_top_bottm.form-switch-tooltip .MuiTooltip-popper {
    left: -18px !important;
    top: 10px !important;
}
