.card {
    width: 570px;
    height: 570px;
    position: relative;
    left: 200px;
}

.headingDiv {
    border-bottom: 1px dashed #D3D8DA;
}

.headingInvite{
    color:#5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 0px;
    opacity: 1;
}

.bottonDiv {
    float: right;
    margin-top: 65px;
}

.fileSelector {
    margin-top: 40px;
}

.fileSelector ul{
    padding-top: unset !important;
    padding-bottom: unset !important;
}


.editorOuter {
    min-height: 170px;
    border: 1px solid #D3D8DA;
}

.editorOuter > .MUIRichTextEditor-toolbar-13 {
    border-bottom: 1px solid #D3D8DA;
}

